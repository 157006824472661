import React from "react";
import Layout from "../components/Layout";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
// import { PageProps } from "../interfaces/page";
import { options } from "../styles/TypographySettings";
import Paper from "@material-ui/core/Paper";
import { setAssets } from "../state/actions/components";
import { connect } from "react-redux";

class Page extends React.Component<any, any> {
  componentWillMount() {
    this.props.setAssets(this.props.pageContext.assets);
  }
  render() {
    const {
      pageContext
    } = this.props;
    const context = pageContext;
    const _copy: any = context.childContentfulPageCopyRichTextNode.json;
    const copy = documentToReactComponents(_copy, options);
    const {
      createdAt,
      id,
      pageTitle,
      showTitle,
      searchIndex,
      metaDescription
    } = context;
    const SEO = { searchIndex, metaDescription };
    const content = (
      <Paper className="page">
        <Grid container className="page__content">
          <Grid item className="page__col">
            <div className="page__section">
              {showTitle ? (
                <Typography variant="h2">{pageTitle}</Typography>
              ) : null}
              {copy}
            </div>
          </Grid>
        </Grid>
      </Paper>
    );
    return (
      <Layout
        components={[{ columns: 12, content, className: "landing" }]}
        authRequired={false}
        seo={SEO}
      />
    );
  }
}

const mapStateToProps = (state: any) => ({
  assets: state.components.assets
});

export default connect(
  mapStateToProps,
  { setAssets }
)(Page);
